import React from 'react'

import Layout from '../components/layout'
import strings from '../components/strings'

const ContactPage = ({ pageContext: { language, originalPath } }) => (
  <Layout language={language} path={originalPath}>
    <h1>{strings[language].contactUs}</h1>
    <p>
      European Computer Services SPRLU
      <br />
      Rue Emile Feron 168 1060
      <br /> Saint-Gilles Belgium
    </p>
    <p>
      <a href="mailto:support@itheorie.ch">EMail</a>
    </p>
  </Layout>
)

export default ContactPage
